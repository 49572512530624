const MelmacUtils = {}

MelmacUtils.showOtherOrg = function(el, otherId) {
  var val = $(el).val();
  if(val == otherId) {
    $('#other-org').show();
  } else {
    $('#other-org').hide();
    $('#other-org input').val('');
  }
}

MelmacUtils.spmAfi = function() {
  setTimeout(function() {
    var form = $('form.spm-afi');
    form.find("input[name='afi']").val(new Date().getTime());
  }, 750);
}

export default MelmacUtils