import jQuery from 'application/shared/window-jquery'
import Rails from "@rails/ujs"
import 'application/public/bootstrap'
import InlineEditor from 'application/admin/inline_editor'
import MelmacUtils from 'application/portal/melmac-utils'

Rails.start();

global.MelmacUtils = MelmacUtils
global.InlineEditor = InlineEditor


