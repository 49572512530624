// This function makes it so that forms that have the class auto-submit-form
// use the rails ajax form submit whenever any input in the form is clicked.

// https://www.tiny.cloud/docs/advanced/usage-with-module-loaders/
// https://www.tiny.cloud/docs/advanced/usage-with-module-loaders/webpack/webpack_es6_npm/
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/code';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/save';

const InlineEditor = {
  anyChanges: function() {
    for (const edId in tinyMCE.editors) {
      if(tinyMCE.editors[edId].isDirty()) {
        return true;
      }
    }
    return false;
  },
  showSaveButton: function() {
    if(this.anyChanges()) {
      $('#save-edits').show();
    } else {
      $('#save-edits').hide();
    }
  },
  save: function() {
    $('.save-notification').show().fadeOut();

    for (edId in tinyMCE.editors) {
      var editor = tinyMCE.editors[edId];
      if(editor.isDirty()) {
        $.ajax({
          method: "POST",
          url: '/admin/editable_texts/up',
          cache: false,
          data: {content: editor.getContent(), model: editor.getElement().dataset.model }
        });
        editor.save();
      }
    }
  },
  initializeTinyMce: function () {
    tinymce.init({
      selector: '[data-editable]',
      inline: true,
      skin_url: '/tinymce-skins/ui/oxide',
      plugins: [
        'autolink link code save',
      ],
      browser_spellcheck: true,
      toolbar: 'undo redo bold italic link | code | save',
      menubar: false,
      save_enablewhendirty: true,
      forced_root_block: '',
      save_onsavecallback: function (editor) {
        $.ajax({
          method: "POST",
          url: '/admin/editable_texts/up',
          cache: false,
          data: {content: editor.getContent(), model: editor.getElement().dataset.model }
        });
        editor.save();
      },
      setup: (editor) => {
        editor.on('keyup', (e) => {
          this.showSaveButton();
        });
        editor.on('change', (e) => {
          this.showSaveButton();
        });
      }
    });
  },
  initialize: function() {
    $('#save-edits').on("click", (e) => {
      this.save();
      this.showSaveButton();
    });

    // Notify if there are any unsaved changes when trying to navigate away
    window.addEventListener("beforeunload", (e) => {
      var confirmationMessage = 'If you leave before saving, your changes will be lost.';
      if(this.anyChanges()) {
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
      }
    });

    this.initializeTinyMce();
  }
}

export default InlineEditor
